"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import { signIn } from "next-auth/react";
import { toast } from "react-toastify";
import { useRouter, useSearchParams } from "next/navigation";
import { useGoogleLogin } from "@react-oauth/google";
import { getGoogleProfile } from "@lib/google";

import GoogleLogo from "@assets/icons/google-logo.png";
import { useIntl } from "react-intl";
import { useCookies } from "next-client-cookies";
import ActivateAccountModal from "@components/Reactive Account/ActivateAccountModal";

interface GoogleLoginButtonProps {
  role?: string;
  setLoading?: (loading: boolean) => void;
}

const GoogleLoginButton = ({ role, setLoading }: GoogleLoginButtonProps) => {
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [reactivationEmail, setReactivationEmail] = useState("");
  const searchParams = useSearchParams();
  const [callbackUrl, setCallbackUrl] = useState<string | null | undefined>("");
  const router = useRouter();
  const cookies = useCookies();
  const pendingCv = searchParams.get("pendingCv") || cookies.get("pendingCv");

  const intl = useIntl();
  const loginTranslation: any = intl.messages.login;

  const login = useGoogleLogin({

    onSuccess: async (response) => {
      try {
        setLoading?.(true);

        const accessToken = response.access_token;
        const profileData = await getGoogleProfile(accessToken);

        if (!profileData) {
          setLoading?.(false);
          return;
        }

        const res: any = await signIn("googleonetap", {
          profileData: JSON.stringify(profileData),
          redirect: false,
          role: role,
          callbackUrl: callbackUrl ?? "/",
        });

        if (res?.error) {
          setLoading?.(false);
          if (res.error === "activate_user_account") {
            setReactivationEmail(profileData.email);
            setShowActivateModal(true);
            return;
          }
          toast.error(res.error);
          return;
        }

        const cookieData = cookies.get("OAuth-new-user");
        const newUser = cookieData ? JSON.parse(cookieData) : undefined;

        if (newUser && newUser.OAuth_new && newUser.role === "CANDIDATE") {
          cookies.remove("OAuth-new-user");
          router.replace("/candidate/profile");
        } else {
          router.replace(res?.url);
        }
      } catch (error) {
        console.error("Error during Google login:", error);

        toast.error(
          "An error occurred during Google login. Please try again later."
        );
        setLoading?.(false);
      }
    },
  });

  return (
    <>
      <button
        className="text-[#FFFFFFCC] w-[240px] h-[48px] rounded-[60px] flex flex-row items-center gap-[10px] justify-center bg-[#FFFFFF08]"
        onClick={() => login()}
      >
        <Image width={36} height={36} src={GoogleLogo} alt="Google Logo" />
        <span className="">{loginTranslation?.["login-with-google"]}</span>
      </button>
      {showActivateModal && (
        <ActivateAccountModal
          email={reactivationEmail}
          onClose={() => setShowActivateModal(false)}
        />
      )}
    </>
  );
};

export default GoogleLoginButton;
